const createState = ({ state, include }) => {
    const crudState = {
        list: [],
        entities: {},
        paging : {
            totalItemsCount: undefined,
        },
        status: {}
    };

    if (include.includes('FETCH_LIST')) {
        Object.assign(crudState.status, {
            isFetchingList: false,
            fetchListError: null
        })
    }

    if (include.includes('FETCH_SINGLE')) {
        Object.assign(crudState.status, {
            isFetchingSingle: false,
            fetchSingleError: null
        })
    }

    if (include.includes('CREATE')) {
        Object.assign(crudState.status, {
            isCreating: false,
            creatingError: null
        })
    }

    if (include.includes('UPDATE')) {
        Object.assign(crudState.status, {
            isUpdating: false,
            updateError: null
        });
    }

    if (include.includes('DELETE')) {
        Object.assign(crudState.status, {
            isDestroying: false,
            destroyError: null
        });
    }

    return Object.assign(crudState, state);
}
export default createState;