import InfoCardService from "@/services/infoCards/info-card.service";
import createModule from "@/store/moduleProvider";
const service = new InfoCardService();

export const infoCards = createModule({
    include: ['CREATE', 'FETCH_LIST', 'FETCH_SINGLE', "UPDATE", "PUT", "DELETE"],
    service: service,
    state: {
        archivedList: []
    },
    getters:{
        archivedList: (state) => state.archivedList,
    },
    mutations: {
        FETCH_ARCHIVED_REQUEST(state) {
            state.status.isFetchingArchivedList = true;
        },
        FETCH_ARCHIVED_SUCCESS(state, response) {
            const { data } = response;
            state.archivedList = data;
            state.status.isFetchingArchivedList = false;
            state.status.fetchArchivedListError = null;
        },
        FETCH_ARCHIVED_ERROR(state, error) {
            state.archivedList = [];
            state.status.fetchArchivedListError = error;
            state.status.isFetchingArchivedList = false;
        }
    },
    actions: {
        async fetchArchived({ commit }, { params }) {           
            commit('FETCH_ARCHIVED_REQUEST');
            try {
                const parsedResult = await service.allOfType("archived", params);
                commit('FETCH_ARCHIVED_SUCCESS', parsedResult);
            }
            catch (parsedError) {
                commit('FETCH_ARCHIVED_ERROR', parsedError);
            }
        },
        async removeOfType({ commit }, { id, type }) {
            commit('DELETE_REQUEST')
            try {
                const parsedResult = await service.removeOfType(id, type)
                commit('DELETE_SUCCESS', parsedResult)
            }
            catch (parsedError) {
                commit('DELETE_ERROR', parsedError)
            }
        },
        async fetchSingleOfType({ commit }, { id, type, include } = { include: [] }) {
            commit('FETCH_SINGLE_REQUEST')
            try {
                const parsedResult = await service.singleOfType(id, type, include)
                commit('FETCH_SINGLE_SUCCESS', parsedResult)
            }
            catch (parsedError) {
                commit('FETCH_SINGLE_ERROR', parsedError)
            }
        },
        async fetchListOfType({ commit }, { type, include, params } = { include: [], params: {} }) {
            commit('FETCH_LIST_REQUEST');
            try {
                const parsedResult = await service.allOfType(type, params, include)
                commit('FETCH_LIST_SUCCESS', parsedResult);
                return parsedResult
            }
            catch (parsedError) {
                commit('FETCH_LIST_ERROR', parsedError);
            }
        }
    }
});