<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <portal-target name="dragable-modal" multiple />
    <transition name="fade" mode="out-in">
      <div
        class="d-flex justify-content-center mt-2 mb-2"
        v-if="isFetchingUserInfo"
      >
        <loader />
      </div>
      <component :is="layout" v-else>
        <router-view />
      </component>
    </transition>
    <b-button
      @click="showTranslationKeys"
      v-if="env == 'DEV'"
      class="translationKeyBtn"
      variant="outline-primary"
      >Show translation keys</b-button
    >
  </div>
</template>

<script>
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { mapActions, mapGetters } from "vuex";
import { useWindowSize, useCssVar } from "@vueuse/core";
import store from "@/store";
import { BButton } from "bootstrap-vue";
import authHelper from "@/auth";
import Loader from "@/app/components/layout/Loader.vue";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  components: {
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    BButton,
    Loader,
  },
  computed: {
    ...mapGetters({ isFetchingUserInfo: "appUser/isFetching" }),
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
    test() {
      return "Hej";
    },
  },
  methods: {
    ...mapActions({
      fetchUserDataAsync: "appUser/fetchUserDataAsync",
    }),
    showTranslationKeys() {
      document.body.classList.add("overflowscroll");
      this.$i18n.locale = "none";
    },
  },
  sockets: {
    CreateOrderNotifaction(data) {
      store.commit("notification/ADD_NOTIFICATION", "New order");
    },
  },

  beforeCreate() {
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  async created() {
    if (authHelper.isAuthorized()) {
      await this.fetchUserDataAsync();
    }
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    if (skin.value === "dark") document.body.classList.add("dark-layout");

    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
    };
  },
  data() {
    return {
      env: process.env.VUE_APP_ENV,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.vue-treeselect__multi-value-item,
.vue-treeselect__value-remove {
  color: #407e3d !important;
}
[dir] .vue-treeselect__multi-value-item {
  background: rgba(64, 126, 61, 0.12) !important;
}

td {
  /*
  border-right: 1px solid #ebe9f1;
  border-left: 1px solid #ebe9f1;
  */
}
.to-show {
  display: none;
}
.to-hover:hover > .to-show {
  display: block;
}
body.overflowscroll,
body.overflowscroll .table-responsive {
  overflow: scroll !important;
}
.translationKeyBtn {
  width: 200px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 9999999999999999;
}
.dark-btn {
  background-color: #000 !important;
  color: #fff !important;
}
.table-responsive {
  /*overflow-x: unset !important;*/
}
div[id^="booststrapmodal___BV_modal_outer_"] {
  z-index: 9999999 !important;
}
#booststrapmodal {
  z-index: 99999999 !important;
}
.modal-container {
  z-index: 99999999 !important;
}
/*
.modal-backdrop {
  z-index: 999999 !important;
}
*/
/*Tooltip*/
.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

/** <--- transitions ---> */

/** <--- Lib adjustments ---> */
// Daterange picker
.vue-daterange-picker {
  width: 100%;
}
.reportrange-text {
  border: 1px solid #d8d6de !important;
}
.daterangepicker td.in-range {
  background-color: #e3ebe1 !important;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #407e3d !important;
}
.vue-daterange-picker {
  .reportrange-text {
    display: flex;
    align-items: center;
  }
}
.daterangepicker.show-calendar .ranges {
  font-weight: normal !important;
}
.daterangepicker .ranges li:hover {
  background-color: #e3ebe1 !important;
}
.daterangepicker .ranges li.active,
td.off.active.in-range.end-date {
  background-color: #407e3d !important;
  color: #fff !important;
}
.daterangepicker .btn-success {
  background-color: #407e3d !important;
  border-color: #407e3d !important;
}
/** <--- Lib adjustments end */
</style>