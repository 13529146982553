export default [
  {
    path: '/collectors/:id',
    name: 'collectors',
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { name: 'supplier-contacts.orders.list', params: { contactId: to.params.id } }
    },
    /*
    component: () => import('@/views/collectors/Collector.vue'),
    meta: {
      layout: 'full',
      allowAnonymous: true
    },
    */
  },

]
