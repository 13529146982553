import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import UUID from "vue-uuid";
import "./libs/notification";
import "./libs/tooltip";
import "@/libs/vue-js-modal"
import "@/libs/vue-cookies"
import "@/libs/vue-browser-geolocation"
import "@/libs/signalr";

import "echarts";

/** <-- gmaps component -->  */
import x5GMaps from 'x5-gmaps';
Vue.use(x5GMaps, { key: 'AIzaSyCFJpJCECrw-PeHPa_aGyx1fzG0JLnDRHg', libraries: ['places'] })
/** <-- gmaps component end -->  */
import Fragment from 'vue-fragment';
Vue.use(Fragment.Plugin);

// VueCroppie
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css' // import the croppie css manually
Vue.use(VueCroppie);

import imageCompressor from 'vue-image-compressor'
Vue.use(imageCompressor);

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(UUID);


// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

/** <--- VueMixIn ---> */

import moment from "moment";
Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
    maximumFractionDigits: 4
  });
  return formatter.format(value).replace("kr.", "").replace("\u00A0", "");

});
import authHelper from "@/auth";
import { mapActions, mapGetters } from "vuex";
import Config from '@/configs'
import Translations from "@/translations.js";

Vue.mixin({
  data() {
    return {
      translations: {},
    }
  },
  methods: {
    ...mapActions({ saveUserSetting: "appUser/setUserSettingAsync" }),
    apiBaseUrl: () => process.env.VUE_APP_APIURL,
    format_date: function (value, format) {
      if (format == undefined || format == "") format = "DD/MM/yyyy";
      if (value) {
        return moment(String(value)).format(format);
      }
      return value;
    },
    format_datetime: function (value, format) {
      if (format == undefined || format == "") format = "DD/MM/yyyy H:mm";
      if (value) {
        return moment(String(value)).format(format);
      }
      return value;
    },
    valueIsNotEmptyOrUnknown(value) {
      if (value == "" || !value || value == "Unknown" || value == null || value == undefined) return false;
      return true;
    },
    groupBy(array, property) {
      return array.reduce(function (rv, x) {
        (rv[x[property]] = rv[x[property]] || []).push(x);
        return rv;
      }, {});
    },
    stringToHash(string) {
      var hash = 0;
      if (string.length == 0) return hash;
      for (var i = 0; i < string.length; i++) {
        var char = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
      }
      return hash;
    },
    isAdmin() {
      return authHelper.isAdmin();
    },
    isSystemAdmin() {
      return authHelper.isSystemAdmin();
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    convertTo3decimals(value) {
      let val = value;
      if (typeof value !== "number" || isNaN(value)) val = 0;

      return val.toLocaleString("da-DK", {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      });
    },
    getContainerSizeUnit(unitKey) {
      let found = this.containerSizeUnits.find(u => u.value == unitKey);
      if (found == undefined) {
        found = { text: "", value: "" }
      }
      return found;
    },
    getWasteCategory(categoryKey) {
      return this.wasteCategories.find(c => c.value == categoryKey)
    },
    timeSince(date) {
      if (date == null) return "--";
      let seconds = Math.floor((new Date() - new Date(date)) / 1000);
      let interval = seconds / 31536000;
      if (interval > 1) {
        return Math.floor(interval) + " years";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes";
      }
      return Math.floor(seconds) + " seconds";
    },
    getUserSetting(key, defaultValue) {
      const setting = this.getUserSettingFromStore(key);
      return (setting != undefined) ? setting : defaultValue;
    },
    async setUserSettingAsync(key, value) {
      await this.saveUserSetting({ key: key, value: value })
    },
    T(key, defaultValue = "") {
      return Translations.T(key, defaultValue);
    }
  },
  computed: {
    ...mapGetters({ getUserSettingFromStore: "appUser/getUserSetting", accountScope: "appUser/getCurrentAccountScope" }),
    userSetting: function () {
      const self = this;
      return function (test) {
        console.log(self.getUserSetting("sdfsd"))

      }
    },
    currencyScope() {
      switch (this.accountScope?.communicationLanguage) {
        case "se": return "SEK"
        default: return "DKK"

      }
    },
    wasteCategories: () => [
      { text: Translations.T("Web.Generic.WasteCategories.None"), value: "Unknown" },
      { text: Translations.T("Web.Generic.WasteCategories.None"), value: "None" },
      { text: Translations.T("Web.Generic.WasteCategories.Rezycl"), value: "Rezycl" },
      { text: Translations.T("Web.Generic.WasteCategories.Reuse"), value: "Reuse" },
      {
        text: Translations.T("Web.Generic.WasteCategories.Combostion"),
        value: "Combostion",
      },
      { text: Translations.T("Web.Generic.WasteCategories.Special"), value: "Special" },
      { text: Translations.T("Web.Generic.WasteCategories.Landfill"), value: "Landfill" },
    ],
    containerSizeUnits: () => [{ text: "m3", value: "Cubic" },
    { text: "L", value: "Litre" },]
  },

})
/** <--- End VueMixIn ---> */

import { i18n } from "./libs/i18n/index";
import ResourceService from './services/base/resource.service'


new Vue({
  router,
  store,
  render: h => h(App),
  i18n,
}).$mount('#app')



