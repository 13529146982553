import authHelper from "@/auth";
const userRoles = authHelper.getForNavsRoles();
const basePath = '/directory/accounts';

export default [
    {
        path: basePath,
        name: 'directory.accounts.index',
        component: () => import('@/app/directory-app/accounts/views/DirectoryAccountsPageView.vue'),
        meta: {
            concernType: "Directory",
            roles: [
              userRoles.SuperAdmin,
              userRoles.Admin,
              userRoles.User,
              userRoles.SystemAdmin
            ],
          },
    },
]