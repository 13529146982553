import ReconciliationService from '@/services/economics/reconciliation.service';
import createModule from '@/store/moduleProvider';
const service = new ReconciliationService()
export const economicsReconciliation = createModule({
    include: ['FETCH_SINGLE', 'UPDATE'],
    service: service,
    actions: {
        async getInfoCardPostingDraft({ commit }, { reconciliationId, invoiceLineId, infoCardId }) {
            const uri = `/${reconciliationId}/postings/info-cards/draft`;
            const response = await service.get(uri, {
                lineId: invoiceLineId,
                infoCardId: infoCardId
            });
            return response.data.data;
        },
        async createInfoCardPosting({ commit, state }, { reconciliationId, posting }) {
            const reconciliation = state.entities[reconciliationId.toString()];
            reconciliation.postings.push(posting);
            try{
                const uri = `/${reconciliationId}/postings/info-cards`;
                const response = await service.post(uri, posting);
                const postingResource = (await service.get(`/${reconciliationId}/postings/${posting.id}`)).data.data;
                //commit('CREATE_POSTING_SUCCESS', { posting: postingResource, reconciliationId: reconciliationId });
            }
            catch(error)
            {
                reconciliation.postings.splice(reconciliation.postings.indexOf(posting), 1);
                throw error;
            }
            return;
        },
        async deletePosting({ commit }, { reconciliationId, postingId }) {
            const uri = `/${reconciliationId}/postings/${postingId}`;
            await service.deleteUri(uri);
            commit('DELETE_POSTING_SUCCESS', { postingId: postingId, reconciliationId: reconciliationId });
        },
        async updateInfoCardPosting({commit}, {posting, reconciliationId})
        {
            const uri = `/${reconciliationId}/postings/info-cards/${posting.id}`;
            await service.patch(uri, posting);
            const updatedPosting = (await service.get(`/${reconciliationId}/postings/${posting.id}`)).data.data;
            commit('UPDATE_POSTING_SUCCESS', {posting: updatedPosting, reconciliationId:reconciliationId})
        },
        async refreshReconciliation({commit, dispatch}, reconciliationId)
        {
            const uri = `/${reconciliationId}/refresh`;
            const response = await service.post(uri);
            await dispatch('fetchSingle', {id: reconciliationId});
        }
    },
    mutations: {
        CREATE_POSTING_SUCCESS(state, { posting, reconciliationId }) {
            const reconciliation = state.entities[reconciliationId.toString()];
            reconciliation.postings.push(posting);
        },
        DELETE_POSTING_SUCCESS(state, { postingId, reconciliationId }) {
            const reconciliation = state.entities[reconciliationId.toString()];
            const indexToSplice = reconciliation.postings.indexOf(reconciliation.postings.find(p => p.id == postingId));
            reconciliation.postings.splice(indexToSplice, 1) 
        },
        UPDATE_POSTING_SUCCESS(state, { posting, reconciliationId })
        {
            const reconciliation = state.entities[reconciliationId.toString()];
            const indexToSplice = reconciliation.postings.indexOf(reconciliation.postings.find(p => p.id == posting.id));
            reconciliation.postings.splice(indexToSplice, 1) 
            reconciliation.postings.push(posting);
        }
    }
});
