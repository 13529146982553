import authHelper from "@/auth";
import AppUserService from "@/services/app/app-user.service";
import router from "@/router";
import config from "@/configs";
import Vue from 'vue'
import { i18n } from "@/libs/i18n/index";

const appUserService = new AppUserService();

function emptyUserState() {
    return {
        status: {
            logginIn: false,
            loggedIn: authHelper.isAuthorized(),
            loginError: null,
            fetching: false,
        },
        profile: null,
        accountScope: null,
        locationScope: null,
    }
}

export const appUser = {
    namespaced: true,
    state: emptyUserState(),
    actions: {
        async updateLanguage({ commit, dispatch }, { languageKey, updateOnAllAccounts }) {
            await appUserService.updateLanguage(
                languageKey, updateOnAllAccounts
            );
            commit("SET_USER_LANGUAGE", {languageKey: languageKey})
        },
        async loginAsync({ commit, dispatch }, credentials) {
            commit('LOGIN_REQUEST')
            try {
                const token = await appUserService.authenticate(
                    credentials
                );
                authHelper.setToken(token);
                await dispatch('fetchUserDataAsync');
                commit('LOGIN_SUCCESS');
                router.push(authHelper.getLoginRedirectRoute());
            }
            catch (error) {
                commit('LOGIN_ERROR', error)
            }
        },

        async logOut({ commit, dispatch }) {
            commit('notification/CLEAR_NOTIFICATION', null, { root: true });

            authHelper.clearToken();
            dispatch("app/clearTheme", null, { root: true });
            router.push(config.auth.logoutRedirectRoute);
            commit('LOGOUT_SUCCESS');
        },

        async fetchUserDataAsync({ commit, dispatch, getters, rootGetters }) {
            if (!authHelper.isAuthorized()) return dispatch('logOut');
            commit('FETCH_USER_DATA_REQUEST');

            Vue.socket.start();

            const userData = await appUserService.fetchUserData();

            dispatch('notification/fetchNotification', null, { root: true });

            const accountScopeId = authHelper.getAccountId();
            if (!authHelper.isSupplier()) await dispatch('locations/fetchList', {}, { root: true });

            await dispatch('app/fetchThemeInfoAsync', null, { root: true });
            const accountLocations = rootGetters['locations/list'];

            commit('FETCH_USER_DATA_SUCCESS', {
                data: userData,
                accountScope: userData.accounts.find(a => a.id == accountScopeId),
                locationScope: (accountLocations.length > 0) ? accountLocations[0] : null,
            });
            commit("SET_USER_LANGUAGE", {languageKey: userData.language})


            const startUpLocationId = getters.getUserSetting("locations.startUpLocationId");
            if (startUpLocationId === null) commit('LOCATION_SCOPE_SWITCH_SUCCESS', null);
            else if (startUpLocationId != undefined) {
                const startUpLocation = accountLocations.find(l => l.id == startUpLocationId);
                if (startUpLocation) commit('LOCATION_SCOPE_SWITCH_SUCCESS', startUpLocation)
            }
        },

        async switchAccountScopeAsync({ commit, dispatch }, accountId) {
            commit('SWITCH_ACCOUNT_SCOPE_REQUEST');
            try {
                commit('notification/CLEAR_NOTIFICATION', null, { root: true });
                const token = await appUserService.refreshTokenForAccount(accountId);
                authHelper.setToken(token);
                await dispatch('fetchUserDataAsync');
                commit('SWITCH_ACCOUNT_SCOPE_SUCCESS');
                router.push(config.auth.SwapRedirectRoute)
            }
            catch (error) {
                commit("LOGIN_ERROR", error);
                dispatch("logOut");
            }
        },

        async setUserSettingAsync({ commit, state }, { key, value }) {
            if (!authHelper.isAuthorized()) return dispatch('logOut');
            commit('UPDATE_USER_SETTING', { key: key, value: value });
            await appUserService.saveUserSettings(state.profile.userSettings)
        },

        async setCurrentLocationScope({ commit, dispatch, rootGetters }, locationId) {
            commit("LOCATION_SCOPE_SWITCH_REQUEST")
            let locationScopeId = null;
            const accountLocations = rootGetters['locations/list'];
            const newLocationScope = accountLocations.find(l => l.id == locationId);
            if (newLocationScope) locationScopeId = newLocationScope.id;
            commit('LOCATION_SCOPE_SWITCH_SUCCESS', newLocationScope)
            await dispatch("setUserSettingAsync", { key: "locations.startUpLocationId", value: locationScopeId })
        }
    },
    getters: {
        isLogginIn: (state) => state.status.logginIn,
        isFetching: (state) => state.status.fetching,
        hasLoginError: (state) => state.status.loginError != null,
        getLoginError: (state) => state.status.loginError,
        getCurrentProfile: (state) => state.profile,
        getCurrentAccountScope: (state) => state.accountScope,
        getCurrentLocationScope: (state) => state.locationScope,
        getUserSettings: (state) => state.profile.userSettings,
        getUserSetting: (state) => (key) => {
            return state.profile.userSettings[key];
        }
    },
    mutations: {
        LOGIN_REQUEST: (state) => {
            state.status.logginIn = true;
            state.status.loginError = null;
        },
        LOGIN_ERROR: (state, error) => {
            state.status.logginIn = false;
            state.status.loginError = error;
        },
        LOGIN_SUCCESS: (state) => state.status.logginIn = false,
        LOGOUT_SUCCESS: (state) => state.profile = emptyUserState(),
        FETCH_USER_DATA_REQUEST: (state) => state.status.fetching = true,
        FETCH_USER_DATA_SUCCESS: (state, { data, accountScope, locationScope }) => {
            state.status.fetching = false;
            state.profile = data;
            state.accountScope = accountScope;
            state.locationScope = locationScope
        },
        SET_USER_LANGUAGE: (state, {languageKey}) => {
            Vue.$cookies.set("SelectedLang", languageKey);
            i18n.locale = languageKey;
        },
        SWITCH_ACCOUNT_SCOPE_REQUEST: (state) => state.status.loggedIn = true,
        SWITCH_ACCOUNT_SCOPE_SUCCESS: (state) => {
            state.status.loggedIn = false;
            state.status.loginError = null;
        },
        LOCATION_SCOPE_SWITCH_REQUEST: () => "",
        LOCATION_SCOPE_SWITCH_SUCCESS: (state, location) => state.locationScope = location,
        UPDATE_USER_SETTING: (state, { key, value }) => {
            state.profile.userSettings[key] = value
            state.profile.userSettings = Object.assign({}, state.profile.userSettings)
        }
    }
}