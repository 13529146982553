import InvoicesService from "@/services/invoices.service";
import createModule from "@/store/moduleProvider";
const service = new InvoicesService();
export const economicsInvoices = createModule({
    include: ['FETCH_LIST', 'FETCH_SINGLE', 'DELETE'],
    service : service,  
    actions:{
        async updateInvoiceNeedsConfirmation({commit}, {id, value})
        {
            await service.updateInvoiceNeedsConfirmation(id, value);
        },
        async fetchAwaitingApprovalList({commit})
        {
            return await service.fetchAwaitingApprovalList();
        },
    }
})






