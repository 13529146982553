import createState from "./factories/stateFactory";
import createActions from "./factories/actionsFactory";
import createMutations from "./factories/mutationsFactory";
import createGetters from "./factories/gettersFactory";

const createModule = ({
    include = [],
    service,
    state = {},
    actions = {},
    mutations = {},
    getters = {},
} = {}) => {
    if(include.length > 0 && !service) throw new Error("A service is required to autogenerate included logic")
    return {
        namespaced: true,
        state: createState({ state, include }),
        actions: createActions({ actions, service, include }),
        mutations: createMutations({ mutations, include }),
        getters: createGetters({getters, include})
    }
}
export default createModule;