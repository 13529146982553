import BaseService from './base.service'

export default class ResourceService extends BaseService {
    constructor(resourceUri) {
        super(resourceUri)
    }

    static instance(resourceUri) {
        return new ResourceService(resourceUri)
    }

    async all(params = {}, include = []) {
        let queryParams = Object.assign(params, (include.length > 0) ? { include: include.toString() } : {});
        return this.unwrapData(await this.get("/", queryParams))
    }

    async single(resourceId, include = []) {
        let queryParams = { include: include.toString() };
        return this.unwrapData(await this.get(`/${resourceId}`, queryParams))
    }

    async singleWith(resourceId, params = {}) {
        return this.unwrapData(await this.get(`/${resourceId}`, params))
    }

    async create(resourceData) {
        return await this.post('/', resourceData)
    }

    async createFromUrl(url, resourceData) {
        return await this.post(url, resourceData)
    }

    async update(resourceData) {
        await this.patch(`/${resourceData.id}`, resourceData);
    }

    async updateFromUrl(url, resourceData) {
        await this.patch(url, resourceData);
    }
    async put2(resourceData) {
        await super.put(`/${resourceData.id}`, resourceData);
    }

    async put(url, resourceData) {
        await super.put(url, resourceData);
    }

    async delete(resourceId) {
        await super.delete(`/${resourceId}`)
    }
    // TO DO : FIX 
    async deleteUri(uri) {
        await super.delete(uri)
    }
}
