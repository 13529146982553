import ResourceService from "@/services/base/resource.service";

export default class InvoicesService extends ResourceService {
    constructor(url) {
        super(url ?? 'economics/invoices')
    }

    async uploadFile(file, invoiceId) {
        const formData = new FormData();
        formData.append('invoiceId', invoiceId);
        formData.append('file', file, file.name);
        await this.post("/upload", formData);
    }

    async getExcelExport(invoiceId) {
        // TO DO: CHANGE
        return await this.getFile(`economics/invoices/${invoiceId}/export`)
    }

    async createManuallImportTask(data) {
        return this.post("/manually", data)
    }

    async sendFailedImportFeedback(invoiceId, data) {
        return this.post(`${invoiceId}/failedReport`, data)
    }

    async saveInvoice(invoice) {
        return this.post(`${invoice.id}`, invoice);
    }

    async updateInvoiceNeedsConfirmation(id, value)
    {
        return this.patch(`${id}/update-invoice-needs-confirmation`, {value: value})
    }

    async fetchAwaitingApprovalList()
    {
        return this.get('/awaiting-approval');
    }

}