import authHelper from "@/auth";
const userRoles = authHelper.getForNavsRoles();

export default [
    {
        path: '/suppliers/forward-rules',
        name: 'suppliers.forward-rules',
        component: () => import('@/app/supplier-app/ordering/views/ForwardRulesView.vue'),
        meta: {
            roles: [
                userRoles.Supplier,
            ],
            concernType: 'Supplier'
        }
    },
    {
        path: `/supplier-contacts/:contactId/orders`,
        name: "supplier-contacts.orders.index",
        component: () => import('@/app/supplier-app/ordering/views/OrdersIndexView.vue'),
        children: [
            {
                path: '',
                name: 'supplier-contacts.orders.list',
                component: () => import('@/app/supplier-app/ordering/views/supplier-contact/SupplierContactOrdersView.vue'),
                meta: {
                    allowAnonymous: true,
                    layout: 'full',
                }
            },
            {
                path: ':id',
                name: 'supplier-contacts.orders.show',
                component: () => import('@/app/supplier-app/ordering/views/supplier-contact/SupplierContactOrdersView.vue'),
                meta: {
                    allowAnonymous: true,
                    layout: 'full',
                }
            },
            {
                path: `:id/accept`,
                name: 'suppliers.orders.accept',
                component: () => import('@/app/supplier-app/ordering/views/supplier-contact/ConfirmOrderView.vue'),
                meta: {
                    allowAnonymous: true,
                    layout: 'full',
                }
            },
        ]
    },
    {
        path: `/suppliers/transports`,
        name: "suppliers.orders.transports",
        component: () => import('@/views/suppliers/SupplierTransports.vue'),
        meta: {
            roles: [
                userRoles.Supplier,
            ],
            concernType: 'Supplier'

        }
    },
    {
        path: `/suppliers/orders`,
        name: "suppliers.orders.index",
        component: () => import('@/app/supplier-app/ordering/views/OrdersIndexView.vue'),
        children: [
            {
                path: '',
                name: 'suppliers.orders.list',
                component: () => import('@/app/supplier-app/ordering/views/supplier/SupplierOrdersView.vue'),
                meta: {
                    roles: [
                        userRoles.Supplier,
                        userRoles.SupSupplier
                    ],
                    mobile: true,
                }
            },
            {
                path: ':id',
                name: 'suppliers.orders.show',
                component: () => import('@/app/supplier-app/ordering/views/supplier/SupplierOrdersView.vue'),
                meta: {
                    roles: [
                        userRoles.Supplier,
                        userRoles.SupSupplier
                    ],
                    mobile: true,
                }
            },
        ]
    },
    {
        path: `/suppliers/transport-vehicles`,
        name: "suppliers.orders.transport-vehicles",
        component: () => import('@/app/supplier-app/ordering/views/SupplierTransportVehicles.vue'),
        meta: {
            roles: [
                userRoles.Supplier,
            ],
            concernType: 'Supplier'

        }
    },

];
