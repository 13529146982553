import ResourceService from "@/services/base/resource.service";
import createModule from "@/store/moduleProvider";
const service = ResourceService.instance('account/users');
export const accountUsers = createModule({
    include: ['FETCH_LIST', 'PUT', 'DELETE', 'UPDATE'],
    service: service,
    actions: {
        async inviceUser({ commit }, data) {
            await service.post("/invite", data)
        },
        async reinviteUser({ commit }, data) {
            await service.post("/reinvite", data)
        },
        async setPassword({ commit }, data) {
            await service.post("/setpassword", data)
        },
        async forgotPassword({ commit }, data) {
            await service.post("/forgotpassword", data)
        },
    }
});