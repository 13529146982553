import WasteDistributionPyramidWidgetService from '@/services/dashboard/widgets/wasteDistributionPyramidWidget-service';
const service = new WasteDistributionPyramidWidgetService();

export const wasteDistributionPyramidWidget = {
    namespaced: true,
    state: {
        loading: false,
        toDateFilter: new Date(),
        fromDateFilter: new Date (new Date().setFullYear(new Date().getFullYear() - 1)),
        data: undefined,
    },
    
    getters:{
        isLoading: (state) => state.loading,
        getFromDateFilter: (state) => state.fromDateFilter,
        getToDateFilter: (state) => state.toDateFilter,
        getDistributionData: (state) => state.data,
    },

    actions: {
        async fetchDistributionData({commit, state}) {
            commit('LOADING_BEGAN');
            try
            {
                var result = await service.getDistributionData(state.fromDateFilter, state.toDateFilter);
                commit('SET_DATA', result);
            }
            finally{
                commit('LOADING_FINISHED');
            }
        },
        updateFromDateFilter({commit, dispatch}, fromDate)
        {
            commit('SET_FROM_DATE_FILTER', fromDate);
            dispatch('fetchDistributionData');
        },
        updateToDateFilter({commit, dispatch}, toDate)
        {
            commit('SET_TO_DATE_FILTER', toDate);
            dispatch('fetchDistributionData');
        },

    },

    mutations:{
        LOADING_BEGAN: (state) => state.loading = true,
        LOADING_FINISHED: (state) => state.loading = false,
        SET_FROM_DATE_FILTER: (state, value) => state.fromDateFilter = value,
        SET_TO_DATE_FILTER: (state, value) => state.toDateFilter = value,
        SET_DATA: (state, value) => state.data = value,
    }
}