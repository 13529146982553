const baseRoute = '/data-insights'
import authHelper from "@/auth";
const userRoles = authHelper.getForNavsRoles();

export default [
    {
        path: baseRoute,
        name: 'data-insights.home',
        component: () => import('@/app/concern-app/data-insights/DataInsightsView.vue'),
        meta: {
            roles: [
                userRoles.Admin,
                userRoles.FacilityUser
            ]
        },
    },
]