import ResourceService from "@/services/base/resource.service";
import createModule from "@/store/moduleProvider";
import { uuid } from "vue-uuid";
const wasteHandlingSimulationsService = ResourceService.instance('economics/simulations/waste-handling-simulations');
const wasteDistributionSimulationsService = ResourceService.instance('economics/simulations/waste-distribution-simulations');


export const simulations = {
    ...createModule({
        include: ['FETCH_LIST', 'CREATE', 'DELETE'],
        service: ResourceService.instance('economics/simulations'),
       
    }),
    namespaced: true,
    modules: {
        wasteDistributionSimulations: createModule({
            include: ['FETCH_SINGLE', 'UPDATE'],
            service: wasteDistributionSimulationsService,
            actions:{
                async fetchSingle1({ commit }, { id, fromDate, toDate }) {
                    commit('FETCH_SINGLE_REQUEST')
                    try {
                        const parsedResult = await wasteDistributionSimulationsService.singleWith(id, { fromDate: fromDate, toDate: toDate })
                        commit('FETCH_SINGLE_SUCCESS', parsedResult)
                    }
                    catch (parsedError) {
                        commit('FETCH_SINGLE_ERROR', parsedError)
                    }
                },
            }
        }),
        wasteHandlingSimulations: createModule({
            include: ['CREATE', 'FETCH_SINGLE', 'UPDATE'],
            service: wasteHandlingSimulationsService,
            actions: {
                async fetchSingle1({ commit }, { id, startDate, endDate }) {
                    commit('FETCH_SINGLE_REQUEST')
                    try {
                        const parsedResult = await wasteHandlingSimulationsService.singleWith(id, { startDate: startDate, endDate: endDate })
                        commit('FETCH_SINGLE_SUCCESS', parsedResult)
                    }
                    catch (parsedError) {
                        commit('FETCH_SINGLE_ERROR', parsedError)
                    }
                },
                async patchWithNoGet({ }, { data }) {
                    await wasteHandlingSimulationsService.patch(`${data.id}`, data);
                },
                async addSimulationItem({ commit }, { simulationId, infocardId }) {
                    try {
                        commit('ADD_SIMULATION_ITEM_REQUEST');
                        const id = uuid.v4();
                        await wasteHandlingSimulationsService.post(`${simulationId}/simulation-items`, { id: id, infocardId: infocardId });
                        const simulationItem = (await wasteHandlingSimulationsService.get(`${simulationId}/simulation-items/${id}`)).data.data;
                        commit('ADD_SIMULATION_ITEM_SUCCESS', simulationItem);
                    }
                    catch { }
                },
                async removeSimulationItem({ commit }, { simulationId, item }) {
                    try {
                        commit('REMOVE_SIMULATION_ITEM_REQUEST')
                        await wasteHandlingSimulationsService.deleteUri(`${simulationId}/simulation-items/${item.id}`);
                        commit('REMOVE_SIMULATION_ITEM_SUCCESS', item)
                    }
                    catch { }
                },
                async updateSimulationItem({ }, { simulationId, id, data }) {
                    await wasteHandlingSimulationsService.put(`${simulationId}/simulation-items/${id}`, data);
                },
                async removeSimulationAlternativItem({ commit }, { simulationId, simulationItemId, id }) {
                    await wasteHandlingSimulationsService.delete(`${simulationId}/simulation-items/${simulationItemId}/Alternativ/${id}`);
                    commit('REMOVE_SIMULATION_ALTERNATIV_ITEM_SUCCESS', { simulationId, simulationItemId, id });
                },
                async getCustomExpenseAsync({ commit }, { simulationId }) {
                    const data = (await wasteHandlingSimulationsService.get(`${simulationId}/customexpense`)).data.data;
                    commit('UPDATE_CUSTOMEXPENSE_ITEM_SUCCESS', { simulationId, data: data });
                }
            },
            mutations: {
                UPDATE_CUSTOMEXPENSE_ITEM_SUCCESS(state, item) {
                    const simulation = state.entities[item.simulationId];
                    item.data.items.forEach(element => {
                        const customexpense = simulation.customExpenses.find(c => c.id == element.id);
                        customexpense.baseline = element.amount;
                    });
                },
                ADD_SIMULATION_ITEM_REQUEST(state) {
                    state.status.isCreating = true;
                },
                ADD_SIMULATION_ITEM_SUCCESS(state, item) {
                    const simulation = state.entities[item.simulationId];
                    simulation.simulationItems.push(item);
                },
                REMOVE_SIMULATION_ITEM_REQUEST(state) {
                    state.status.isDestroying = true;
                },
                REMOVE_SIMULATION_ITEM_SUCCESS(state, item) {
                    const simulation = state.entities[item.simulationId];
                    const itemToRemove = simulation.simulationItems.find(
                        (i) => i.id == item.id
                    );
                    const indexToRemove = simulation.simulationItems.indexOf(itemToRemove);
                    simulation.simulationItems.splice(indexToRemove, 1);
                },
                REMOVE_SIMULATION_ALTERNATIV_ITEM_SUCCESS(state, item) {
                    const simulation = state.entities[item.simulationId];
                    const simulationItem = simulation.simulationItems.find(
                        (i) => i.id == item.simulationItemId
                    );
                    const itemToRemove = simulationItem.alternatives.find(
                        (i) => i.id == item.id
                    );
                    const indexToRemove = simulationItem.alternatives.indexOf(itemToRemove);
                    simulationItem.alternatives.splice(indexToRemove, 1);
                }
            }
        })
    }
};