import Config from '@/configs'
import axios from "axios";
import authHelper from '@/auth'
import { stringify } from 'query-string';
import { i18n } from "@/libs/i18n/index";
import Vue from 'vue'

export default class RezyclApiClient {
    httpClient;
    constructor(uri = "") {
        const url = `${Config.api.hostname}/${uri}`;
        const axiosConfig = {
            baseURL: url,
            timeout: 30000,
        };
        this.httpClient = axios.create(axiosConfig);
        this.httpClient.interceptors.request.use(
            (request) => requestHandler(request));

        this.httpClient.interceptors.response.use(
            (response) => responseHandler(response),
            (error) => errorHandler(error)
        );
    }
    async get(url, params = {}) {
        return await this.httpClient.get(`${url}?${stringify(params)}`);
    }
    async post(url, data) {
        try {
            return await this.httpClient.post(url, data);
        } catch (error) {
            if (!error.response) throw error;
            throw error.response;
        }
    }
    async put(url, data)
    {
        return await this.httpClient.put(url, data)
    }
}

const requestHandler = request => {
    if (!authHelper.isAuthorized()) return request;
    request.headers.common = { 'Authorization': `Bearer ${authHelper.getToken()}` }
    return request;
};
const responseHandler = response => {
    const { data } = response;
    return data.data;
};
const errorHandler = error => {
    let errorMessage = "";
    if (error.response && error.response.data.message)
        errorMessage = error.response.data.message.replaceAll(".", "_");

    let searchText = "Web.Generic.Errors." + errorMessage;
    let text = i18n.t(searchText);

    if (text == searchText)
        text = "An error happend";

    Vue.notify({
        group: 'errors',
        title: "Error",
        text: text,
        type: 'error'
    });
    throw error;
};