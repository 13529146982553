import IntegrationsService from "@/services/integrations.service";
import createModule from "@/store/moduleProvider";
import { uuid } from "vue-uuid";

const service = new IntegrationsService('settings/integrations');

export const integrations = createModule({
    include: ['FETCH_LIST', 'CREATE', 'DELETE'],
    service: service,
    actions: {
        async createFtpImportIntegration({ commit }, { data, resourceId = uuid.v4() }) {
            commit('CREATE_REQUEST')
            try {
                data.id = resourceId;
                await service.createFtpImportIntegration(data)
                const parsedResult = await service.getFtpImportIntegration(resourceId);
                commit('CREATE_SUCCESS', parsedResult)
            }
            catch (parsedError) {
                commit('CREATE_ERROR', parsedError)
            }
        },

        async createAzureBlobIntegration({ commit }, { data, resourceId = uuid.v4() }) {
            commit('CREATE_REQUEST')
            try {
                data.id = resourceId;
                await service.createAzureBlobIntegration(data)
                const parsedResult = await service.getAzureBlobIntegration(resourceId);
                commit('CREATE_SUCCESS', parsedResult)
            }
            catch (parsedError) {
                commit('CREATE_ERROR', parsedError)
            }
        },

        async deleteFtpImportIntegration({ commit }, resourceId) {
            commit('DELETE_REQUEST')
            try {
                await service.deleteFtpImportIntegration(resourceId);
                commit('DELETE_SUCCESS', resourceId)
            }
            catch (parsedError) {
                commit('DELETE_ERROR', parsedError)
            }
        },

        async deleteAzureBlobIntegration({ commit }, resourceId) {
            commit('DELETE_REQUEST')
            try {
                await service.deleteAzureBlobIntegration(resourceId);
                commit('DELETE_SUCCESS', resourceId)
            }
            catch (parsedError) {
                commit('DELETE_ERROR', parsedError)
            }
        }
    },
});