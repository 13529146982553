import Vue from 'vue';
import createModule from "@/store/moduleProvider";
import InvoicesService from "@/services/invoices.service";
import { uuid } from 'vue-uuid';
import ReconciliationService from '@/services/economics/reconciliation.service';
const service = new InvoicesService('economics/invoices/imports');
export const invoiceImports = createModule({
    include: ['FETCH_LIST', 'DELETE'],
    service: service,
    actions: {
        async updateInvoicesOnList({ commit }, invoices) {
            let idsToInclude = invoices.map(i => i.id);
            const parsedResult = await service.all({ IncludeIds: idsToInclude })
            commit('UPDATE_ITEMS_ON_LIST', parsedResult);
        },
        async uploadInvoiceImportFiles({ state }, files) {
            for (const file of files) {
                const invoiceId = uuid.v4();
                await service.uploadFile(file, invoiceId);
                const response = await service.all({ IncludeIds: invoiceId })
                const { data } = response;
                const id = data[0]['id'].toString();
                Vue.set(state.entities, id, data[0]);
                state.list.push(id);
            }
        },
        async createInvoiceImportTaskManually({state}, {invoiceData})
        {
            const invoiceId = uuid.v4();
            invoiceData.id = invoiceId;
            await service.createManuallImportTask(invoiceData)
            const response = await service.single(invoiceId);
            const { data } = response;
            const id = data['id'].toString();
            Vue.set(state.entities, id, data);
            state.list.push(id);
        },
        async confirmReconciliation({state}, id)
        {
            const reconService = new ReconciliationService();
            await reconService.confirmReconciliation(id);
            state.list.splice(state.list.indexOf(id),1);
        },
        async reopenReconciliation({state}, id)
        {
            const reconService = new ReconciliationService();
            return await reconService.reopenReconciliation(id);
        },
        async removeFromList({commit}, id)
        {
            commit('DELETE_SUCCESS', id)
        }
    },
    mutations: {
        UPDATE_ITEMS_ON_LIST: (state, response) => {
            const { data } = response;
            data.forEach((m) => {
                Vue.set(state.entities, m['id'].toString(), m);
            });
        },
    },
})