import Vue from 'vue';

const createMutations = ({ mutations, include }) => {
    const crudMutations = {};

    if (include.includes('FETCH_LIST')) {
        Object.assign(crudMutations, {
            FETCH_LIST_REQUEST(state) {
                state.status.isFetchingList = true;
            },
            FETCH_LIST_SUCCESS(state, response) {
                const { data } = response;
                const { paging } = response
                data.forEach((m) => {
                    Vue.set(state.entities, m['id'].toString(), m);
                });
                state.list = data.map(m => m['id'].toString());
                if(paging != undefined)
                {
                    state.paging.currentPage = paging.currentPage;
                    state.paging.totalItemsCount = paging.totalItems
                }
                else
                {
                    state.paging.currentPage = 1;
                    state.paging.totalItemsCount = state.list.length;
                }
                state.status.isFetchingList = false;
                state.status.fetchListError = null;
            },
            FETCH_LIST_ERROR(state, error) {
                state.list = [];
                state.status.fetchListError = error;
                state.status.isFetchingList = false;
            }
        })
    }

    if (include.includes('FETCH_SINGLE') || include.includes('FETCH_SINGLE_WITH')) {
        Object.assign(crudMutations, {
            FETCH_SINGLE_REQUEST(state) {
                state.status.isFetchingSingle = true;
            },
            FETCH_SINGLE_SUCCESS(state, response) {
                const { data } = response;
                const id = data['id'].toString();

                Vue.set(state.entities, id, data);

                state.status.isFetchingSingle = false;
                state.status.fetchSingleError = null;
            },
            FETCH_SINGLE_ERROR(state, error) {
                state.list = [];
                state.status.fetchSingleError = error;
                state.status.isFetchingSingle = false;
            }
        })
    }

    if (include.includes('CREATE')) {
        Object.assign(crudMutations, {
            CREATE_REQUEST(state) {
                state.status.isCreating = true;
            },
            CREATE_SUCCESS(state, response) {
                const { data } = response
                if (data) {
                    const id = data['id'].toString();
                    Vue.set(state.entities, id, data);
                    state.list.unshift(id)
                }
                state.status.isCreating = false;
                state.status.createError = null;
            },
            CREATE_ERROR(state, error) {
                state.status.isCreating = false
                state.status.createError = error
            }
        })
    }

    if (include.includes('UPDATE')) {
        Object.assign(crudMutations, {
            UPDATE_REQUEST(state) {
                state.status.isUpdating = true;
            },
            UPDATE_SUCCESS(state, response) {
                const { data } = response;
                const id = data['id'].toString();
                Vue.set(state.entities, id, data);
                const listIndex = state.list.indexOf(id);
                if (listIndex >= 0) {
                    Vue.set(state.list, listIndex, id);
                }
                state.status.isUpdating = false;
                state.status.updateError = null;
            },
            UPDATE_ERROR(state, error) {
                state.status.isUpdating = false
                state.status.updateError = error
            }
        })
    }
    if (include.includes('PUT')) {
        Object.assign(crudMutations, {
            PUT_REQUEST(state) {
                state.status.isUpdating = true;
            },
            PUT_SUCCESS(state, response) {
                const { data } = response;
                const id = data['id'].toString();
                Vue.set(state.entities, id, data);
                const listIndex = state.list.indexOf(id);
                if (listIndex >= 0) {
                    Vue.set(state.list, listIndex, id);
                }
                state.status.isUpdating = false;
                state.status.updateError = null;
            },
            PUT_ERROR(state, error) {
                state.status.isUpdating = false
                state.status.updateError = error
            }
        })
    }
    if (include.includes('DELETE')) {
        Object.assign(crudMutations, {
            DELETE_REQUEST(state) {
                state.status.isDestroying = true;
            },
            DELETE_SUCCESS(state, id) {
                const listIndex = state.list.indexOf(id);
                if (listIndex >= 0) {
                    state.list.splice(listIndex, 1);
                }
                delete state.entities[id];
                state.status.isDestroying = false;
                state.status.destroyError = null;
            },
            DELETE_ERROR(state, error) {
                state.status.isDestroying = false
                state.status.destroyError = error
            }
        })
    }


    return Object.assign(crudMutations, mutations);
}
export default createMutations;