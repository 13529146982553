import authHelper from "@/auth";
const userRoles = authHelper.getForNavsRoles();
export default [
    {
        path: '/settings',
        name: 'settings',
        component: () => import('@/components/layout/Page.vue'),
        children: [
            {
                path: 'integrations',
                name: 'settings.integrations',
                component: () => import('@/views/settings/Integrations/ListIntegrationsView'),
                meta: {
                    pageTitle: 'Integrations',
                    roles: [
                        userRoles.SuperAdmin
                    ]
                }
            }
        ],
        meta: {
            roles: [
                userRoles.SuperAdmin
            ]
        }
    }
]