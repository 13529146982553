import { uuid } from 'vue-uuid';
const createActions = ({ actions, service, include }) => {
    const crudActions = {};
    if (include.includes('FETCH_LIST')) {
        Object.assign(crudActions, {
            async fetchList({ commit }, { params, include } = { params: {}, include: [] }) {
                commit('FETCH_LIST_REQUEST');
                try {
                    const parsedResult = await service.all(params, include);
                    commit('FETCH_LIST_SUCCESS', parsedResult);
                    return parsedResult
                }
                catch (parsedError) {
                    commit('FETCH_LIST_ERROR', parsedError);
                }
            },
        })
    }

    if (include.includes('FETCH_SINGLE')) {
        Object.assign(crudActions, {
            async fetchSingle({ commit }, { id, include, } = { include: [] }) {
                commit('FETCH_SINGLE_REQUEST')
                try {
                    const parsedResult = await service.single(id, include)
                    commit('FETCH_SINGLE_SUCCESS', parsedResult)
                }
                catch (parsedError) {
                    commit('FETCH_SINGLE_ERROR', parsedError)
                }
            }
        })
    }

    if (include.includes('CREATE')) {
        Object.assign(crudActions, {
            async create({ commit }, { data, resourceId = uuid.v4() }) {
                commit('CREATE_REQUEST')
                try {
                    data.id = resourceId
                    await service.create(data)
                    const parsedResult = await service.single(resourceId)
                    commit('CREATE_SUCCESS', parsedResult)
                }
                catch (parsedError) {
                    commit('CREATE_ERROR', parsedError)
                }
            }
        })
    }

    if (include.includes('UPDATE')) {
        Object.assign(crudActions, {
            async update({ commit }, { data }) {
                commit('UPDATE_REQUEST')
                try {
                    await service.update(data)
                    const parsedResult = await service.single(data.id)
                    commit('UPDATE_SUCCESS', parsedResult)
                }
                catch (parsedError) {
                    commit('UPDATE_ERROR', parsedError)
                }
            }
        })
    }

    if (include.includes('PUT')) {
        Object.assign(crudActions, {
            async put({ commit }, { data }) {
                commit('PUT_REQUEST')
                try {
                    await service.put2(data)
                    const parsedResult = await service.single(data.id)
                    commit('PUT_SUCCESS', parsedResult)
                }
                catch (parsedError) {
                    commit('PUT_ERROR', parsedError)
                }
            }
        })
    }

    if (include.includes('DELETE')) {
        Object.assign(crudActions, {
            async delete({ commit }, { resourceId }) {
                commit('DELETE_REQUEST')
                try {
                    await service.delete(resourceId)
                    commit('DELETE_SUCCESS', resourceId)
                }
                catch (parsedError) {
                    commit('DELETE_ERROR', parsedError)
                }
            }
        })
    }

    return Object.assign(crudActions, actions);
}
export default createActions;