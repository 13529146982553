import ResourceService from "@/services/base/resource.service";

export default class IntegrationsService extends ResourceService {
    constructor(url) {
        super(url)
    }

    async createFtpImportIntegration(data)
    {
        return await this.post('/ftp-imports', data)
    }

    async createAzureBlobIntegration(data)
    {
        return await this.post('/azure-blobs', data)

    }

    async getFtpImportIntegration(resourceId)
    {
        return this.unwrapData(await this.get(`/ftp-imports/${resourceId}`))
    }

    async getAzureBlobIntegration(resourceId)
    {
        return this.unwrapData(await this.get(`/azure-blobs/${resourceId}`))
    }

    async deleteFtpImportIntegration(resourceId){
        return await this.httpClient.delete(`/ftp-imports/${resourceId}`);
    }

    async deleteAzureBlobIntegration(resourceId){
        return await this.httpClient.delete(`/azure-blobs/${resourceId}`);
    }
}