import { uuid } from "vue-uuid";
import AppUserService from "@/services/app/app-user.service";
const appUserService = new AppUserService();

export default {
    namespaced: true,
    state: {
        data: [],
        shownNotification: false,
    },
    getters: {
        getNotifications: (state) => state.data,
        notificationsShown: (state) => state.shownNotification
    },
    mutations: {
        SET_SHOWN(state, value) {
            state.shownNotification = value;
        },
        ADD_NOTIFICATION(state, message, header) {
            state.data.push({
                message: message,
                header: header
            });

            state.shownNotification = false;
        },
        ADD_NOTIFICATIONS(state, notifications) {
            state.data = state.data.concat(notifications);
            state.shownNotification = false;
        },
        REMOVE_NOTIFICATION(state, id) {
            let elm = state.data.find((t) => t.id == id);
            let index = state.data.indexOf(elm);
            state.data.splice(index, 1);
        },
        CLEAR_NOTIFICATION(state) {
            state.data = [];
        },
    },
    actions: {
        setShown({ commit }, value) {
            commit('SET_SHOWN', value);
        },
        async removeNotification({ commit }, id) {
            await appUserService.MarkUserNotification(id);
            commit('REMOVE_NOTIFICATION', id);
        },
        async fetchNotification({ commit }) {
            appUserService.fetchUserNotification().then((data) => {
                commit('ADD_NOTIFICATIONS', data);
            });
        }
    },
}
