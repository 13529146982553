import ResourceService from "@/services/base/resource.service";
import createModule from "@/store/moduleProvider";
import { uuid } from "vue-uuid";
const service = ResourceService.instance('concerns/orders');
export const ordering = createModule({
    include: ['FETCH_LIST', 'FETCH_SINGLE', 'UPDATE', 'DELETE'],
    service: service,
    actions: {
        async createMultiple({ dispatch }, orderList) {
            for (const order of orderList) {
                const form_data = new FormData();
                order.id = uuid.v4();
                if (order.files != undefined) {
                    Array.from(order.files).forEach(file => {
                        form_data.append('files', file, file.name);
                    });
                }
                for (var key in order) {
                    if (key == "infoCard" || key == "files") continue;
                    form_data.append(key, order[key]);
                }
                await service.post('/', form_data)
                await dispatch("infocards/fetchSingle", { id: order.infoCardId }, { root: true })
            }
        }
    }
});