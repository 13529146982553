import authHelper from "@/auth";
const userRoles = authHelper.getForNavsRoles();
const baseRoute = '/collection-orders'

export default [
    {
        path: baseRoute,
        name: 'ordering-index',
        component: () => import('@/views/ordering/OrdersView.vue'),
        meta: {
            pageTitle: 'OrdersView.Route.Title',
            roles: [
                userRoles.Admin,
                userRoles.User,
            ],
            mobile: true,
        },
    },

]