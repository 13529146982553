import ConcernService from '@/services/concern/concern-services';
import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    themeColor: undefined,
    customLogo: undefined
  },
  actions: {
    async fetchThemeInfoAsync({ commit, dispatch }) {
      dispatch("clearTheme");
      let themeInfo = await new ConcernService().getThemeInfo();
      if (themeInfo.themeColor) commit("UPDATE_THEME_COLOR", themeInfo.themeColor);
      if (themeInfo.logoPath) commit("UPDATE_Custom_Logo", themeInfo.logoPath);
    },
    clearTheme({ commit }) {
      commit("UPDATE_THEME_COLOR", "#407e3d");
      commit("UPDATE_Custom_Logo", "");
    }
  },
  getters: {
    customLogo: state => {
      return state.customLogo;
    },
    themeColor: state => {
      return state.themeColor;
    },
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_Custom_Logo(state, val) {
      state.customLogo = val;
    },
    UPDATE_THEME_COLOR(state, val) {
      state.themeColor = val;
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
}
