export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      mobile: true,
      allowAnonymous: true
    },
  },
  {
    path: '/AcountUser/Register/:email',
    name: 'user-register',
    component: () => import('@/views/auth/RegisterFromInvite.vue'),
    meta: {
      layout: 'full',
      mobile: true,
      allowAnonymous: true
    },
  },
]
