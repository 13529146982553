import AuthService from "@/services/auth-service";
import authHelper from "@/auth";
import config from "@/configs";
import router from "@/router";
import ConcernService from "@/services/concern/concern-services";
import store from "@/store";

export const auth = {
  namespaced: true,
  state: {
    currentUser: {
      loading: false,
      userInfo: null,
      accountInfo: null
    },
    isLoggedIn: authHelper.isAuthorized(),
    isLogginIn: false,
  },

  actions: {
    async login({ commit, dispatch }, credentials) {
      commit('LOGIN_REQUEST')

      try {
        const token = await new AuthService().getTokenFromCredentials(
          credentials
        );
        authHelper.setToken(token);
        await dispatch('loadUserInfo');
        await dispatch('getTheme');
        commit('LOGIN_SUCCESS');
        if (authHelper.getRole() == "SuperAdmin") {
          router.push(config.auth.SuperAdminloginRedirectRoute)
        } else {
          router.push(config.auth.loginRedirectRoute)
        }
      }
      catch (error) {

      }
    },
    async logOut({ commit }) {
      authHelper.clearToken();
      commit('clearCurrentUser')
      store.commit("app/UPDATE_THEME_COLOR", "#407e3d");
      store.commit("app/UPDATE_Custom_Logo", "");

      router.push(config.auth.logoutRedirectRoute);
    },
    async loadUserInfo({ commit }) {

      if (!authHelper.isAuthorized()) return
      commit('loadUserInfoRequest')
      const user = await new AuthService().getUserInfo();
      const currentAccount = user.accounts.find(account => account.id === authHelper.getAccountId());
      commit('setUserInfo', user);
      commit('setAccountInfo', currentAccount);
      commit('loadUserInfoSuccess');
    },
    async changeAccount({ dispatch }, accountId) {
      const token = await new AuthService().refreshTokenForAccount(accountId)
      authHelper.setToken(token)
      await dispatch('loadUserInfo');
      await dispatch('getTheme');

      router.push(config.auth.SwapRedirectRoute)

    },
    async getTheme() {
      if (!authHelper.isAuthorized()) return

      let themeInfo = await new ConcernService().getThemeInfo();
      if (themeInfo.themeColor)
        store.commit("app/UPDATE_THEME_COLOR", themeInfo.themeColor);

      if (themeInfo.logoPath)
        store.commit("app/UPDATE_Custom_Logo", themeInfo.logoPath);
    }
  },
  mutations: {
    LOGIN_REQUEST: (state) => state.isLoggedIn = true,
    LOGIN_SUCCESS: (state) => state.isLogginIn = false,
    loadUserInfoRequest: (state) => state.currentUser.loading = true,
    loadUserInfoSuccess: (state) => state.currentUser.loading = false,
    setUserInfo: (state, user) => state.currentUser.userInfo = user,
    setAccountInfo: (state, account) => state.currentUser.accountInfo = account,
    clearCurrentUser: (state) => state.currentUser = { userInfo: null, accountInfo: null },
  },
  getters: {
    loggedIn: (state) => authHelper.isAuthorized(),
    getUser: (state) => state.currentUser.userInfo,
    getAccount: (state) => state.currentUser.accountInfo,
    userIsLoading: (state) => state.currentUser.loading,
    isLogginIn: (state) => state.isLogginIn,
  },
};
