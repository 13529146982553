import BaseService from "../base/base.service";

export default class ConcernService extends BaseService {
  constructor() {
    super("Account");
  }

  async getThemeInfo() {
    const response = await this.get("/theme");
    return response.data.data;
  }

  async setThemeColor(color) {
    await this.put("/theme/color", { themeColor: color });
  }
  async setThemeLogo(file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return await this.put("/theme/logo", formData);
  }

  async resetThemeLogo() {
    return await this.put("/theme/logo/reset", {});
  }

}
