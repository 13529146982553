import authHelper from "@/auth";
const userRoles = authHelper.getForNavsRoles();
const baseRoute = '/admin'
import translations from "@/translations";
export default [
    {
        path: baseRoute + "/background",
        name: 'background-index',
        component: () => import('@/views/sysadmin/BackgroundData.vue'),
        meta: {
            pageTitle: translations.T("Web.BackgroundDataPage.Title", "Background data"),
            roles: [
                userRoles.Admin
            ]
        },
    },
    {
        path: baseRoute + "/locaitons",
        name: 'locaitons-index',
        component: () => import('@/views/sysadmin/Locations.vue'),
        meta: {
            pageTitle: 'Generic.Locations',
            roles: [
                userRoles.Admin
            ]
        },
    },
    {
        path: baseRoute + "/ewccodes",
        name: 'ewccodes-index',
        component: () => import('@/views/sysadmin/EwcCodes.vue'),
        meta: {
            pageTitle: 'EwcCodes.Route.Title',
            roles: [
                userRoles.Admin
            ]
        },
    },
    {
        path: baseRoute + "/theme",
        name: 'theme-index',
        component: () => import('@/views/sysadmin/Theme.vue'),
        meta: {
            pageTitle: translations.T("Web.ThemePage.Title", "Theme"),
            roles: [
                userRoles.SuperAdmin,
                userRoles.SystemAdmin
            ]
        },
    },
    {
        path: baseRoute + "/integrations",
        name: 'integrations',
        component: () => import('@/views/settings/Integrations/ListIntegrationsView'),
        meta: {
            pageTitle: translations.T('Web.IntegrationsPage.Title', 'Integrations'),
            roles: [
                userRoles.SuperAdmin,
                userRoles.SystemAdmin
            ]
        },
    },
]