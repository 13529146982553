import authHelper from "@/auth";
const userRoles = authHelper.getForNavsRoles();
const baseRoute = '/infocards';
import translations from "@/translations";
export default [
    {
        path: baseRoute,
        name: 'infocards.list',
        component: () => import('@/app/views/background-data/infocards/ListLocationInfocardsView.vue'),
        meta: {
            pageTitle: translations.T("Web.InfocardsPage.Title", "Infocards"),
            roles: [
                userRoles.Admin,
            ],
            showLocationScopePicker: true,
            mobile: true,

        },

    },
    {
        path: `${baseRoute}/archived`,
        name: 'infocards.archived',
        component: () => import('@/views/infocards/ArchivedInfocardsListView.vue'),
        meta: {
            pageTitle: translations.T("Web.ArchivedInfocardsPage.Title", "Archived Infocards"),
            roles: [
                userRoles.Admin
            ],
        },
    }
]