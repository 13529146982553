import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import { economics } from '@/app/concern-app/economics/economics.store'
import {user} from '@/app/concern-app/user/user.store'


import { appUser } from './app/app-user.module'
import { locations } from './app/background-data/locations.module'
import { infocards } from './app/background-data/infocards.module'
import { ordering } from './app/ordering.module'





import { locationsOld } from './modules/locations/locations.module'

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu/index'
import notification from './vertical-menu/notification'
import { auth } from './modules/auth-module'
import { economicsInvoices } from './modules/economics/invoices2.module'
import { economicsInvoiceImports } from './modules/economics/invoiceimports.module'
import { containerTypes } from './modules/infoCards/container-types.module'
import { wasteFractions } from './modules/waste-fractions.module'
import { ewcs } from './modules/ewcs.module'
import { collectors } from './modules/collections/collectors.module'
import { recivingFacilities } from './modules/collections/reciving-facility.module'
import { infoCards } from './modules/infoCards/info-cards.module'
import { wasteDistributionPyramidWidget } from './modules/dashboard/waste-distribution-pyramid.widget.module'
import { accountUsers } from './modules/auth/account-user.module'
import { supplierContacts } from './modules/economics/supllier-contacts.module'
import { agreementBasis } from './modules/economics/agreement-basis.module'
import { invoiceInspections } from './modules/invocing/invoice-inspections.module'
import { invoices } from './modules/economics/invoices.module'
import { invoiceImports } from './modules/economics/invoice-imports.module'
import { tenders } from './modules/economics/tender.module'
import { integrations } from './modules/settings/integrations.module'
import { economicsReconciliation } from './modules/economics/reconciliation.module'
import { transportvehicle } from './modules/transport-vehicle.module'
import { containers } from './modules/containers.module'
import {orderForwardRules} from '@/app/supplier-app/modules/order-forward-rules.module'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    economics,
    user,
    orderForwardRules,
  

    containers,

    appUser,
    locations,
    infocards,
    ordering,
    transportvehicle,
    locationsOld,
    app,
    appConfig,
    verticalMenu,
    auth,
    economicsInvoices,
    economicsInvoiceImports,
    infoCards,
    containerTypes,
    wasteFractions,
    ewcs,
    collectors,
    recivingFacilities,
    accountUsers,
    wasteDistributionPyramidWidget,
    supplierContacts,
    agreementBasis,
    invoiceInspections,
    invoices,
    invoiceImports,
    tenders,
    integrations,
    economicsReconciliation,
    notification
  },
  strict: process.env.DEV,
})
