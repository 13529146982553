const config = {
  api: {
    hostname: process.env.VUE_APP_APIURL,
    timeout: 30000
  },
  auth: {
    tokenName: "_rezycl_identity",
    SwapRedirectRoute: { name: "redirect" },
    loginRedirectRoute: { name: "home" },
    SuperAdminloginRedirectRoute: { name: "user-admin" },
    logoutRedirectRoute: { name: "auth-login" },
  },
};
export default config;
