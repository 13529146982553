import authHelper from "@/auth";
const userRoles = authHelper.getForNavsRoles();
const baseRoute = '/ordering'
import translations from "@/translations";
export default [
    {
        path: baseRoute,
        name: 'ordering.index',
        component: () => import('@/app/views/ordering/OrderView.vue'),
        meta: {
            pageTitle: translations.T("Web.OrderingPage.Title", "Orders"),
            roles: [
                userRoles.Admin,
                userRoles.User,
                userRoles.FacilityUser
            ],
            showLocationScopePicker: true,
            mobile: true,
        },
    },
]