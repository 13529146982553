import ResourceService from "../base/resource.service";
export default class ReconciliationService extends ResourceService {
  constructor() {
    super('economics/reconciliations')
  }

  async confirmReconciliation(id)
  {
    const uri = `/${id}/confirm`;
    return await this.post(uri);
  }
  
  async reopenReconciliation(id)
  {
    const uri = `/${id}/reopen`;
    return await this.post(uri);
  }

  async getPostingHistoryFromInfoCardId(id)
  {
    const uri = `/posting-history/info-card-posting/${id}`;
    return (await this.get(uri)).data.data;
  }

  async setLocationScope(id, scopeId)
  {
    const uri = `/${id}/location-scope-id`;
    return await this.post(uri, {locationScopeId: scopeId});
  }


  /*
  constructor() {
    // TO DO: Grap from config
    super('economics/reconciliations')
  }

  async getPostingCandidateForInvoiceLine(invoiceLineId)
  {
    return this.unwrapData(await this.get(`/posting-candidate/${invoiceLineId}`)).data
  }

  async saveReconciliationDraft(reconciliationId, postings)
  {
    return this.patch(`/${reconciliationId}`, {postings: postings});
  }

  async saveAndCloseReconciliation(reconciliationId, postings)
  {
    return this.patch(`/${reconciliationId}`, {postings: postings, closeReconciliation: true});
  }
  */
}


