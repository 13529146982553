import ResourceService from "@/services/base/resource.service";

export default class InfocardsService extends ResourceService {
    constructor() {
        super('background-data/infocards')
    }

    async create(data) {
        return await this.post(`/${this.getUriFromType(data.type)}`, data);
    }

    async update(resourceData) {
        await this.patch(`/${this.getUriFromType(resourceData.type)}/${resourceData.id}`, resourceData);
    }

    getUriFromType(type) {
        switch (type) {
            case "ContainerInfoCard":
                return "containers";
            case "AccountInfoCard":
                return "accounts";
            default: throw error("Unknown type");
        }
    }
}