import Config from '@/configs'
const secret = "kGQESXCeBvxF9y7w6LpAbH";
let languageDevCache = {};
import { i18n } from "@/libs/i18n/index";
let useDbTranslations = process.env.VUE_APP_USE_DB_TRANSLATIONS;

if(useDbTranslations == 'true')
{
    var xhr = new XMLHttpRequest();
    xhr.open('GET', `${Config.api.hostname}/translations/items/s/${secret}`, false);
    xhr.send();
    let response = JSON.parse(xhr.responseText);
    response.data.forEach(element => {
        languageDevCache[element.key] = element.eN_Text
    });
}

export default {
    T(key, defaultValue = "") {
        if (useDbTranslations == 'false') return i18n.t(key);
        if(languageDevCache[key] != undefined)
        {
            return languageDevCache[key];
        }
        var xhr = new XMLHttpRequest();
        xhr.open('GET', `${Config.api.hostname}/translations/items/${key}/s/${secret}`, false);
        xhr.send();
        let response = JSON.parse(xhr.responseText);
        if (response.data.key == null) {
            var createXhr = new XMLHttpRequest();
            createXhr.open('POST', `${Config.api.hostname}/translations/items/s/${secret}`, false);
            createXhr.setRequestHeader('Content-Type', 'application/json');
            var data = {
                key: key,
                eNText: defaultValue
            };
            createXhr.send(JSON.stringify(data));
            return defaultValue;
        }
        else {
            return response.data.eN_Text;
        }
    }
}
