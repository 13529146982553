import authHelper from "@/auth";
const userRoles = authHelper.getForNavsRoles();
const basePath = '/economics/vouchers';

export default [
    {
        path: basePath,
        name: 'economics.vouchers.index',
        component: () => import('@/app/concern-app/economics/vouchers/views/ListVouchersView.vue'),
        meta: {
            roles: [
                userRoles.Admin
            ]
        },
    },
]