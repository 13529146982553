import Vue from 'vue'
import VueI18n from 'vue-i18n'
import cookies from 'vue-cookies'
Vue.use(VueI18n);

export const i18n = new VueI18n({
  silentFallbackWarn: true,
  locale: cookies.get("SelectedLang"), // set locale
  fallbackLocale: "en",
  messages: loadJsonLocaleMessages()
});

function loadJsonLocaleMessages() {
  const locales = require.context('./locales', false, /[A-Za-z0-9-_,\s]+\.json$/i);

  let obj = createMockTrans(JSON.parse(JSON.stringify(locales("./en.json"))));

  const messages = {
    none: obj
  };
  locales.keys().forEach(key => {

    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)

    }
  });

  return messages;
}

function createMockTrans(trans) {
  let result = getTranslatsions("", trans, {});
  return result;
}

function getTranslatsions(prefix, list, result) {
  for (const [key, value] of Object.entries(list)) {
    let id = prefix != ""
      ? prefix + "." + key
      : key;
    if (typeof value == typeof {}) {
      getTranslatsions(id, value, result);
    } else {
      result[id] = id;
    }
  }
  return result;
}