import axios from "axios";
import authHelper from '@/auth'
import { stringify } from 'query-string';
import Config from '@/configs'
import Vue from 'vue'
import { i18n } from "@/libs/i18n/index";


export default class BaseService {
  httpClient;

  constructor(uri) {
    const url = `${Config.api.hostname}/${uri}`;
    const axiosConfig = {
      baseURL: url,
      timeout: 30000,
    };
    this.httpClient = axios.create(axiosConfig);

    this.httpClient.interceptors.request.use(
      (request) => requestHandler(request));

    this.httpClient.interceptors.response.use(
      (response) => responseHandler(response),
      (error) => errorHandler(error)
    );
  }
  async getFile(uri) {
    axios({
      url: `${Config.api.hostname}/${uri}`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization: `Bearer ${authHelper.getToken()}` }
    }).then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');
      var filename = "";
      var disposition = response.headers['content-disposition'];
      if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
      }
      fileLink.href = fileURL;
      fileLink.setAttribute('download', filename);
      document.body.appendChild(fileLink);

      fileLink.click();
    });
  }
  async get(url, params = {}) {
    return await this.httpClient.get(`${url}?${stringify(params)}`);
  }
  async getAnonymous(url, params = {}) {
    return await this.httpClient.get(`${url}?${stringify(params)}`);
  }
  async postAnonymous(url, data = {}) {
    return await this.httpClient.post(url, data);
  }
  async deleteAnonymous(url) {
    return await this.httpClient.delete(url);
  }
  async delete(url) {
    return await this.httpClient.delete(url);
  }
  async post(url, data) {
    try {
      const response = await this.httpClient.post(url, data);
      if (response.data != undefined) return response.data;

      return response;
    } catch (error) {
      if (!error.response) throw error;
      throw error.response;
    }
  }
  async put(url, data) {
    try {
      const response = await this.httpClient.put(url, data);
      if (response.data != undefined) return response.data;

      return response;
    } catch (error) {
      if (!error.response) throw error;
      throw error.response;
    }
  }
  async patch(url, data) {
    try {
      const response = await this.httpClient.patch(url, data);
      if (response.data != undefined) return response.data;

      return response;
    } catch (error) {
      if (!error.response) throw error;
      throw error.response;
    }
  }
  unwrapData(response) {
    return response['data']
  }
}
const requestHandler = request => {
  if (!authHelper.isAuthorized()) return request;

  request.headers.common = { 'Authorization': `Bearer ${authHelper.getToken()}` }
  return request;
};
const responseHandler = response => {
  return response;
};
const errorHandler = error => {
  let errorMessage = "";
  console.log(error)
  if (error.response && error.response.data.message)
    errorMessage = error.response.data.message.replaceAll(".", "_");

  let searchText = "Web.Generic.Errors." + errorMessage;
  let text = i18n.t(searchText);

  if (text == searchText)
    text = "An error happend";
/*
  Vue.notify({
    group: 'errors',
    title: "Error",
    text: text,
    type: 'error'
  });
*/
  throw error;
};