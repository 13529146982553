import authHelper from "@/auth";
const userRoles = authHelper.getForNavsRoles();

export default [
    {
        path: "/file-drive",
        name: 'file-drive.index',
        component: () => import('@/app/concern-app/files/views/FileDriveIndexView.vue'),
        meta: {
            roles: [
                userRoles.User,
                userRoles.Admin,
                userRoles.FacilityUser
            ]
        }
    },
]