import authHelper from "@/auth";
const userRoles = authHelper.getForNavsRoles();
const baseRoute = '/admin'
import translations from "@/translations";

export default [
    {
        path: baseRoute + "/users",
        name: 'users.index',
        component: () => import('@/views/sysadmin/Users.vue'),
        meta: {
            pageTitle: translations.T("Web.UsersPage.Title", "Users"),
            roles: [
                userRoles.Admin
            ],
            showLocationScopePicker: true,

        },
    },
]