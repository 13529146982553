import authHelper from "@/auth";
const userRoles = authHelper.getForNavsRoles();
const baseRoute = '/economics'
import translations from "@/translations";
export default [
  {
    path: '/economics/invoices',
    component: () => import('@/views/economics/invoices/InvoicesHomeView.vue'),
    name: 'economics.invoices',
    children: [
      {
        path: '',
        name: 'economics.invoices.list',
        component: () => import('@/views/economics/invoices/ListInvoicesView.vue'),
        meta: {
          pageTitle: translations.T("Web.InvoicesPage.Title", "Invoices"),
          roles: [
            userRoles.Admin
          ]
        },
      },
      {
        path: ':id',
        name: 'economics.invoices.preview',
        component: () => import('@/views/economics/invoices/InvoicePreviewView.vue'),
        meta: {
          pageTitle: translations.T('Web.Generic.Invoices.PreviewInvoice', 'Preview invoice'),
          roles: [
            userRoles.Admin
          ]
        },
      },
      {
        path: ':id/edit',
        name: 'economics.invoices.edit',
        component: () => import('@/views/economics/invoices/EditInvoiceView.vue'),
        meta: {
          pageTitle: translations.T('Web.Generic.Invoices.EditInvoice', 'Edit invoice'),
          roles: [
            userRoles.Admin
          ]
        },
      },
      {
        path: ":id/xml/preview",
        name: 'economics.invoices.xml.edit',
        component: () => import('@/views/economics/reconciliation/components/XmlInvoicePreview.vue'),
        meta: {
          layout: 'full',
        }

      }
    ]
  },

  {
    path: '/economics/tenders',
    component: () => import('@/views/economics/tenders/TendersHomeView.vue'),
    name: 'economics.tenders',
    children: [
      {
        path: '',
        name: 'economics.tenders.list',
        component: () => import('@/views/economics/tenders/ListTendersView.vue'),
        meta: {
          closed: true,
          pageTitle: 'Tenders.Route.Title',
          roles: [
            userRoles.Admin
          ]
        },
      },
      {
        path: 'create',
        name: 'economics.tenders.create',
        component: () => import('@/views/economics/tenders/CreateTendersView.vue'),
        meta: {
          closed: true,
          pageTitle: 'Tenders.Route.Title',
          roles: [
            userRoles.Admin
          ]
        },
      },
    ]

  },
  {
    path: `${baseRoute}/invoices/:invoiceId/reconciliation`,
    name: 'economics-reconciliation',
    component: () => import('@/views/economics/reconciliation/ReconciliationView.vue'),
    meta: {
      roles: [
        userRoles.Admin
      ]
    },
  },
  {
    path: '/economics/invoice-inspections',
    name: 'economics.invoice-inspections.list',
    component: () => import('@/views/economics/invoice-inspections/ListInvoiceInspectionsView.vue'),
    meta: {
      roles: [
        userRoles.Admin
      ]
    }
  },
  {
    path: '/economics/invoice-inspections/:id',
    name: 'economics.invoice-inspections.show',
    component: () => import('@/views/economics/invoice-inspections/ReviewInvoiceInspectionVIew.vue'),
    meta: {
      roles: [
        userRoles.Admin
      ]
    }
  },

  {
    path: '/economics/agreementBasis',
    name: 'economics.agreementBasis',
    component: () => import('@/views/economics/agreementBasis/index.vue'),
    meta: {
      roles: [
        userRoles.Admin
      ]
    }
  },

  {
    path: '/economics/simulations',
    name: 'economics.simulations',
    component: () => import('@/views/economics/simulations/Index.vue'),
    meta: {
      roles: [
        userRoles.Admin
      ]
    }
  },
  {
    path: '/economics/reporting/amounts',
    name: 'economics.reporting.amounts',
    component: () => import('@/views/economics/reporting/AmountsReporting.vue'),
    meta: {
      roles: [
        userRoles.Admin,
        userRoles.FacilityUser,
      ],
      showLocationScopePicker: true

    }
  },
  {
    path: '/economics/reporting/postings',
    name: 'economics.reporting.postings',
    component: () => import('@/views/economics/reporting/PostingsReporting.vue'),
    meta: {
      roles: [
        userRoles.Admin,
        userRoles.FacilityUser,

      ],
      showLocationScopePicker: true

    }
  },

  {
    path: '/economics/reporting/drivings',
    name: 'economics.reporting.drivings',
    component: () => import('@/views/economics/reporting/DrivingsReporting.vue'),
    meta: {
      roles: [
        userRoles.Admin
      ],
    }
  },

]
