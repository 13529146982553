import ResourceService from "@/services/base/resource.service";
import createModule from "@/store/moduleProvider";

const service = ResourceService.instance('economics/invoice-inspections');

export const invoiceInspections = createModule({
    include: [
        'FETCH_LIST', 
        'FETCH_SINGLE'
    ],
    service: service,
    actions:{
        async fetchAppendingApproval({commit})
        {
            commit('FETCH_LIST_REQUEST');
            try {
                const result = await service.get("", {includeOnlyWithStatus: "AwaitsApproval"});
                commit('FETCH_LIST_SUCCESS', result.data);
            }
            catch (parsedError) {
                commit('FETCH_LIST_ERROR', parsedError);
            }
        },
        async fetchApproved({commit})
        {
            commit('FETCH_LIST_REQUEST');
            try {
                const result = await service.get("", {includeOnlyWithStatus: "Approved"});
                commit('FETCH_LIST_SUCCESS', result.data);
            }
            catch (parsedError) {
                commit('FETCH_LIST_ERROR', parsedError);
            }
        }
    },
    getters:{
        inspectionsAwaitingApproval : (state) => state.list.map(id => state.entities[id.toString()]).filter(i => i.status == 'AwaitsApproval'), 
        inspectionsApproved : (state) => state.list.map(id => state.entities[id.toString()]).filter(i => i.status == 'Completed'), 
    }
});