import RezyclApiClient from "../base/rezycl-api-client";
import { i18n } from "@/libs/i18n/index";
import Translations from "@/translations.js";

export default class AppUserService extends RezyclApiClient {
    async updateLanguage(languageKey, updateOnAllAccounts) {
        await this.post("auth/language", { language: languageKey, updateAllAccount: updateOnAllAccounts });
    }

    async authenticate(credentials) {
        try {
            const response = await this.post("user-auth/authenticate", credentials);
            return response.token;
        } catch (error) {
            switch (error.status) {
                case 401:
                    throw Translations.T("Web.LoginPage.Errors.InvalidCredentials", "Email or password incorrect");
                default:
                    throw Translations.T("Web.LoginPage.Errors.Unknown", "Login failed. An unknown error happend");
            }
        }
    }

    async refreshTokenForAccount(accountId) {
        try {
            const response = await this.post(`user-auth/switch-account`, { accountId: accountId });
            return response.token;
        } catch (error) {
            switch (error.status) {
                case 401:
                    throw "Invalid token";
                default:
                    throw "Unknwon error";
            }
        }
    }

    async fetchUserData() {
        return await this.get("user-auth/me");
    }

    async fetchUserNotification() {
        return await this.get("user-notifications/notifications");
    }

    async MarkUserNotification(id) {
        return await this.put(`user-notifications/notifications/${id}`, {});
    }

    async saveUserSettings(userSettings) {
        return await this.put("user-auth/user-settings", userSettings);
    }
}