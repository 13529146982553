const createGetters = ({ getters, include }) => {
    return Object.assign({
        list: (state) => state.list.map(id => state.entities[id.toString()]),
        totalItemsCount: (state) => state.paging.totalItemsCount,
        byId(state) {
            return id => state.entities[id.toString()];
        },
        isError(state) {
            return state.status.fetchListError !== null ||
                state.status.fetchSingleError !== null ||
                state.status.createError !== null ||
                state.status.updateError !== null ||
                state.status.replaceError !== null ||
                state.status.destroyError !== null;
        },
        isFetchingList: (state) => state.status.isFetchingList,
        isLoading(state) {
            return state.status.isFetchingList ||
                state.status.isFetchingSingle ||
                state.status.isCreating ||
                state.status.isUpdating ||
                state.status.isReplacing //||
            //state.status.isDestroying;
        }
    }, getters);
}
export default createGetters;