import { uuid } from "vue-uuid";
import BaseService from "../base/base.service";
import ResourceService from "../base/resource.service";

export default class InfoCardService extends BaseService {
    constructor() {
        super('info-cards')
    }

    async create(data) {
        return await this.post(`/${this.getUriFromType(data.type)}`, data);
    }

    async update(resourceData) {
        await this.patch(`/${this.getUriFromType(resourceData.type)}/${resourceData.id}`, resourceData);
    }

    async put2(resourceData) {
        let url = `/${this.getUriFromType(resourceData.type)}/${resourceData.id}`;
        await super.put(url, resourceData);
    }

    async all(params = {}, include = []) {
        let queryParams = Object.assign(params, (include.length > 0) ? { include: include.toString() } : {});
        return this.unwrapData(await this.get("/", queryParams))
    }

    async allOfType(type, params = {}, include = []) {
        let queryParams = Object.assign(params, (include.length > 0) ? { include: include.toString() } : {});
        return this.unwrapData(await this.get(`/${this.getUriFromType(type)}/`, queryParams))
    }

    async single(resourceId, include = []) {
        let queryParams = { include: include.toString() };
        return this.unwrapData(await this.get(`/${resourceId}`, queryParams))
    }

    async singleOfType(resourceId, type, include = []) {
        let queryParams = { include: include.toString() };
        return this.unwrapData(await this.get(`/${this.getUriFromType(type)}/${resourceId}`, queryParams))
    }
    async removeOfType(resourceId, type) {
        return this.unwrapData(await this.delete(`/${this.getUriFromType(type)}/${resourceId}`))
    }


    getUriFromType(type) {
        switch (type) {
            case "ContainerInfoCard":
                return "containers";
            case "AccountInfoCard":
                return "accounts";
            case "archived":
                return "archived";

            default: throw error("Unknown type");
        }
    }

    async createContainerInfoCard(containerData) {
        const data = containerData;
        data.id = uuid.v4();
        const resourceService = new ResourceService('info-cards/containers');
        resourceService.create(data);
    }

    async createAccountInfoCard(accountData) {
        const data = accountData;
        data.id = uuid.v4();
        const resourceService = new ResourceService('info-cards/accounts');
        resourceService.create(data);
    }

}