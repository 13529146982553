import VueJwtDecode from "vue-jwt-decode";
import config from "@/configs";

export default {
  roles: {
    User: "User",
    Admin: "Admin",
    Supplier: "Supplier",
    SupSupplier: "SupSupplier",
    SuperAdmin: "SuperAdmin",
    SystemAdmin: "SystemAdmin",
    FacilityUser : "FacilityUser"
  },

  setToken: (token) =>
    localStorage.setItem(config.auth.tokenName, token),
  clearToken: () => localStorage.removeItem(config.auth.tokenName),
  isAuthorized() {
    return this.getToken() != undefined;
  },
  getToken() {
    const token = localStorage.getItem(config.auth.tokenName);
    if (!token) return undefined;

    const jwt = VueJwtDecode.decode(token);
    const expiresAt = new Date(jwt.exp * 1000);
    const hasExpired = expiresAt < new Date();
    if (hasExpired) {
      this.clearToken();
      return undefined;
    }
    return token;
  },
  getAccountId() {
    const token = this.getToken()
    if (token === undefined) return undefined
    const jwt = VueJwtDecode.decode(token);
    return jwt.aid
  },
  isAdmin() {
    const role = this.getRole();

    return role == this.roles.Admin || role == this.roles.SuperAdmin || role == this.roles.SystemAdmin;
  },
  isSuperAdmin() {
    const role = this.getRole();

    return role == this.roles.SuperAdmin;
  },
  isSystemAdmin() {
    const role = this.getRole();
    return role == this.roles.SuperAdmin || role == this.roles.SystemAdmin;
  },
  isSupplier() {
    let role = this.getRole();
    return role == this.roles.Supplier || role == this.roles.SupSupplier;
  },
  getRole() {
    const token = this.getToken()
    if (token === undefined) return undefined
    const jwt = VueJwtDecode.decode(token);
    return jwt.role
  },
  getRoles() {
    return {
      User: "User",
      Admin: "Admin",
      Supplier: "Supplier",
      FacilityUser: "FacilityUser",
    }
  },
  AssigableRolesForSuperAdmin() {
    return {
      SystemAdmin: "SystemAdmin",
    }
  },
  getForNavsRoles() {
    return this.roles;
  },
  getLoginRedirectRoute() {
    return (this.getRole() == "SuperAdmin") ? config.auth.SuperAdminloginRedirectRoute : config.auth.loginRedirectRoute;
  }

};
