import ResourceService from "@/services/base/resource.service";
import createModule from "@/store/moduleProvider";

const service = ResourceService.instance('locations');

export const locationsOld = createModule({
    include: ['FETCH_LIST', 'CREATE', 'PUT', 'DELETE'],
    service: service,
    actions: {
        async reloadLocationsInfoCards({ commit })
        {
            commit('RELOAD_INFOCARDS_ACCOUNTS_REQUEST');
            try {
                const parsedResult = await service.all({}, ["infoCards"])
                commit('RELOAD_INFOCARDS_ACCOUNTS_SUCCESS', parsedResult);
                return parsedResult
            }
            catch (parsedError) {
                commit('RELOAD_INFOCARDS_ACCOUNTS_ERROR', parsedError);
            }
        }
    },
    mutations:{
        RELOAD_INFOCARDS_ACCOUNTS_SUCCESS(state, response)
        {
            const locations = response.data;
            locations.forEach(location => {
                const stateLocation = state.entities[location.id]
                stateLocation.infoCards = location.infoCards;
            });
        },
        RELOAD_INFOCARDS_ACCOUNTS_REQUEST(state)
        {

        },
        RELOAD_INFOCARDS_ACCOUNTS_ERROR(state) {

        }
    }
});