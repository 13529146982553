import BaseService from "./base/base.service";
import ResourceService from "./base/resource.service";
import { i18n } from "@/libs/i18n/index";

export default class AuthService extends BaseService {
  constructor() {
    super("Auth");
  }
  
  async getTokenFromCredentials(credentials) {
    try {
      const response = await this.post("/authenticate", credentials);
      return response.data.token;
    } catch (error) {
      switch (error.status) {
        case 401:
          throw i18n.t("Web.LoginPage.Errors.InvalidCredentials");
        default:
          throw i18n.t("Web.Generic.Errors.Unknown");
      }
    }
  }

  async getUserInfo() {
    const response = await this.get("/me");
    return response.data.data;
  }

  async refreshTokenForAccount(accountId) {
    try {
      let service = new ResourceService("Account");
      const response = await service.post(`${accountId}/Swap`);
      return response.data.token;
    } catch (error) {
      switch (error.status) {
        case 401:
          throw "Invalid token";
        default:
          throw "Unknwon error";
      }
    }
  }
}
