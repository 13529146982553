import authHelper from "@/auth";
const userRoles = authHelper.getForNavsRoles();
const basePath = '/economics/simulations';

export default [
    {
        path: basePath,
        name: 'economics.simulations.index',
        component: () => import('@/app/concern-app/economics/simulations/views/SimulationsIndexView.vue'),
        children: [
            {
                path: ``,
                name: 'economics.simulations.list',
                component: () => import('@/app/concern-app/economics/simulations/views/SimulationsListView.vue'),
                meta: {
                    roles: [
                        userRoles.Admin,
                        userRoles.FacilityUser
                    ]
                }
            },
            {
                path: `waste-handling/:id`,
                name: 'economics.simulations.waste-handling-simulation.edit',
                component: () => import('@/app/concern-app/economics/simulations/views/WasteHandlingSimulationEditView.vue'),
                meta: {
                    roles: [
                        userRoles.Admin
                    ]
                }
            },
            {
                path: `waste-distribution/:id`,
                name: 'economics.simulations.waste-distribution-simulation.edit',
                component: () => import('@/app/concern-app/economics/simulations/views/WasteDistributionSimulationEditView.vue'),
                meta: {
                    roles: [
                        userRoles.Admin
                    ]
                }
            }
        ],
    },
]