import BaseService from "@/services/base/base.service";
import moment from 'moment'

export default class WasteDistributionPyramidWidgetService extends BaseService {
    constructor() {
        super('widgets/distribution-widget')
    }

    async getDistributionData(fromDate, toDate) {
        return (await super.get('/', {fromDate: moment(fromDate).format('YYYY-MM-DD'), toDate: moment(toDate).format('YYYY-MM-DD')})).data.data;
    }
}