import authHelper from "@/auth";
const userRoles = authHelper.getForNavsRoles();

export default [
  {
    path: '/supplier/home',
    name: 'supplier-home',
    component: () => import('@/views/suppliers/SupplierOrders.vue'),
    meta: {
      concernType: "Supplier",

      roles: [
        userRoles.Supplier,
        userRoles.SupSupplier,

      ],
      mobile: true,
    },
  },
  {
    path: '/supplier/tenders',
    name: 'supplier-tenders',
    component: () => import('@/views/suppliers/SupplierTender.vue'),
    meta: {
      concernType: "Supplier",

      roles: [
        userRoles.Supplier
      ]
    },
  },
  {
    path: '/supplier/:id/tenders',
    name: 'supplier-tenders',
    component: () => import('@/views/suppliers/SupplierTender.vue'),
    meta: {

      layout: 'full',
      allowAnonymous: true
    },
  },
  {
    path: '/supplier/orders',
    name: 'supplier-orders',
    component: () => import('@/views/suppliers/SupplierOrders.vue'),
    meta: {
      concernType: "Supplier",

      roles: [
        userRoles.Supplier,
        userRoles.SupSupplier,
      ]
    },
  },
  {
    path: '/supplier/Users',
    name: 'supplier-users',
    component: () => import('@/views/suppliers/SupplierUser.vue'),
    meta: {
      concernType: "Supplier",

      roles: [
        userRoles.Supplier
      ],
      mobile: true,
    },
  },
  {
    path: '/suplliers/orders/completed',
    name: 'supplier-orders.completed',
    component: () => import('@/views/suppliers/SupplierOrdersCompleted.vue'),
    meta: {
      concernType: "Supplier",
      roles: [
        userRoles.Supplier
      ]
    }
  }
]
