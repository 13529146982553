import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import authHelper from "@/auth";
import auth from './routes/auth'

import supplierOrdering from '@/app/supplier-app/ordering/ordering.routes';
import directoryRoutes from '@/app/directory-app/directory.routes';
import userSettingsRoutes from '@/app/common/user-settings/user-settings.routes';






import infocards from './app-routes/background-data/infocards.routes'
import ordering from './app-routes/ordering.routes'
import sysadmin from './app-routes/sysadmin.routes'

import economics from '@/app/concern-app/economics/economics.routes';
import files from '@/app/concern-app/files/files.routes';
import user from '@/app/concern-app/user/user.routes';


import suppliers from './routes/suppliers'
import collectors from './routes/collectors'
import economics1 from './routes/economics'
import dashboard2 from './routes/dashboard'
import ordering2 from './routes/ordering'
import sysadmin2 from './routes/sysadmin'
import settings from './routes/settings'
import { i18n } from "@/libs/i18n/index";
const userRoles = authHelper.getForNavsRoles();
import dataInsights from './routes/data-insights'
import translations from '@/translations';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...supplierOrdering,
    ...user,
    ...dataInsights,
    ...directoryRoutes,
    ...userSettingsRoutes,
    ...auth,
    ...ordering,
    ...sysadmin,
    ...economics,
    ...files,


    ...collectors,
    ...economics1,
    ...dashboard2,
    ...infocards,
    ...ordering2,
    ...sysadmin2,
    ...settings,
    ...suppliers,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        allowAnonymous: true
      },
    },
    {
      path: '/redirect',
      name: 'redirect',
      component: () => import('@/views/apputil/RedirectView.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/CreateLanguageFile',
      name: 'CreateLanguageFile',
      component: () => import('@/views/apputil/CreateLanguageFile.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/CreateInfoCardUtil',
      name: 'CreateInfoCardUtil',
      component: () => import('@/views/apputil/CreateInfoCardUtil.vue'),
      meta: {
        roles: [
          userRoles.SuperAdmin,
          userRoles.Admin,
        ],
        mobile: true,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/images/*',
      name: 'image-proxy',
      component: () => import('@/components/image-server/ImageApiProxy.vue'),
    },
    {
      path: "/user-admin",
      name: 'user-admin',
      component: () => import('@/views/superadmin/useradmin.vue'),
      meta: {
        pageTitle: translations.T('Web.DirectoryUserPage.Title', 'Directory users'),
        concernType: "Directory",
        roles: [
          userRoles.SuperAdmin
        ],
        mobile: true
      },
    },
    {
      path: "/privacy/ios",
      name: "privacy.ios",
      redirect: to => {
        window.location = '/files/Rezycl-privacy-Policy.pdf';
      },
      meta: {
        allowAnonymous: true,
        layout: 'full'

      }
    },
    {
      path: "/privacy/app",
      name: "privacy.app",
      component: () => import('@/app/views/privacy-policy/MobilePrivacyPolicy.vue'),
      meta: {
        allowAnonymous: true,
        layout: 'full'

      }
    },
    {
      path: "/translations/s/:secret",
      name: "translations",
      component: () => import('@/views/apputil/TranslationsPage.vue'),
      meta: {
        allowAnonymous: true,
        layout: 'full'

      }
    },
    {
      path: "/weight-stations/registrations",
      name: "weight-stations.registrations",
      component: () => import('@/views/sysadmin/weightStationsRegistrationsView.vue'),
      meta: {
        roles: [
          userRoles.SystemAdmin, userRoles.SuperAdmin
        ]
      }
    },
    {
      path: "/test/pdf-parsing",
      name: "test.pdf-parsing",
      component: () => import('@/views/test/PdfTestView.vue'),
      meta: {
        allowAnonymous: true,
        layout: 'full'

      }
    },

  ],
})

router.beforeEach(async (to, _, next) => {
  if (to.meta.allowAnonymous) return next();
  const isLoggedIn = authHelper.isAuthorized();
  const role = authHelper.getRole();

  let currentRoles = [];
  currentRoles.push(role);
  if (role == "SuperAdmin" || role == "SystemAdmin") {
    currentRoles.push(authHelper.getRoles().Admin);
    currentRoles.push(authHelper.getRoles().Supplier);
  }

  if (!canNavigate(to) && !isLoggedIn) {
    return next({ name: 'auth-login' });
  }

  if (to.name === "redirect") {
    return next();
  }

  if (to.fullPath == "/" && authHelper.isSupplier()) {
    return next({ name: 'supplier-home' });
  }

  if (isMobile() && (!to.meta.mobile)) {
    // Redirect to roders view on mobile 
    return next({ name: "ordering.index" });
  }

  if (to.meta.roles) {
    const intersection = currentRoles.filter((element) =>
      to.meta.roles.includes(element)
    );

    if (intersection.length <= 0) {
      Vue.notify({
        group: 'errors',
        title: i18n.t("Web.Generic.RestrictedAccess"),
        text: i18n.t("Web.Generic.LooksLikeYouDoNotHaveAccess"),
        type: 'error'
      });
      return;
    }
  }

  return next();
})

function isMobile() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
}

export default router
